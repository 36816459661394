// extracted by mini-css-extract-plugin
export var aboutUs = "about-us-module--aboutUs--lx67N";
export var aboutUs1 = "about-us-module--aboutUs1--WQkIj";
export var aboutUsFeatures = "about-us-module--aboutUsFeatures--942yA";
export var aboutUsFeatures1 = "about-us-module--aboutUsFeatures1--MbY7j";
export var aboutUsIntro = "about-us-module--aboutUsIntro--6ctwt";
export var aboutUsSection = "about-us-module--aboutUsSection--Ltmuo";
export var aboutUsText = "about-us-module--aboutUsText---mOcT";
export var bannerSuper = "about-us-module--bannerSuper--OR+xD";
export var button = "about-us-module--button--GGPxF";
export var canadianSupply = "about-us-module--canadianSupply--9BhWD";
export var caption = "about-us-module--caption--ORkjZ";
export var cardLabel1 = "about-us-module--cardLabel1--AevyN";
export var ccs = "about-us-module--ccs--ASlFG";
export var column = "about-us-module--column--YfC7S";
export var display = "about-us-module--display--Sth9l";
export var email = "about-us-module--email--74es3";
export var feature = "about-us-module--feature--w3AOG";
export var footer = "about-us-module--footer--ORjjJ";
export var footerContent = "about-us-module--footerContent--fl0w6";
export var footerL = "about-us-module--footerL--ZTeus";
export var footerLinks = "about-us-module--footerLinks--7Rth4";
export var footerR = "about-us-module--footerR--eujKl";
export var fullNavBar = "about-us-module--fullNavBar--kxETT";
export var grid2x2 = "about-us-module--grid2x2--4z9Cs";
export var grid2x21 = "about-us-module--grid2x21--vSM6r";
export var grid2x22 = "about-us-module--grid2x22--jnw78";
export var heroText = "about-us-module--heroText--KT+Px";
export var iconButton = "about-us-module--iconButton--FiDIA";
export var iconButton1 = "about-us-module--iconButton1--f9frH";
export var iconFilled = "about-us-module--iconFilled--IpMkO";
export var iconFilled2 = "about-us-module--iconFilled2--5ZrxM";
export var imageIcon = "about-us-module--imageIcon--mHCzy";
export var infowebsitecom = "about-us-module--infowebsitecom--Xj-J7";
export var keyValues = "about-us-module--keyValues--4Eytp";
export var label = "about-us-module--label--ydwMd";
export var logo = "about-us-module--logo--k1VNU";
export var logo1 = "about-us-module--logo1--wUWL0";
export var navbarContent = "about-us-module--navbarContent--awCoM";
export var navbarContentMobile = "about-us-module--navbarContentMobile--c5g+B";
export var navigation = "about-us-module--navigation--ExPWf";
export var navigation1 = "about-us-module--navigation1--hh4kw";
export var p = "about-us-module--p--m3E3g";
export var patternsIcon = "about-us-module--patternsIcon--jA9ew";
export var privacyPolicy = "about-us-module--privacyPolicy--AZLSC";
export var row1 = "about-us-module--row1--0sGqg";
export var row11 = "about-us-module--row11--wYFVs";
export var row12 = "about-us-module--row12--iWtDB";
export var tcpSupplyLogoIcon = "about-us-module--tcpSupplyLogoIcon--uzN8q";
export var tcpSupplyLogoIcon2 = "about-us-module--tcpSupplyLogoIcon2--pMxQK";
export var text = "about-us-module--text--0ux3z";
export var title = "about-us-module--title--Z-Sx3";
export var title1 = "about-us-module--title1--F3ssM";
export var visaIcon = "about-us-module--visaIcon--NVmZy";