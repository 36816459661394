import * as React from "react";
import { FunctionComponent, useState, useCallback } from "react";
import { navigate } from "gatsby";
import * as styles from "./about-us.module.css";
import Layout from "../components/Base/Layout";

const AboutUs: FunctionComponent = () => {

  const onButton1Click = useCallback(() => {
    navigate("/about-us");
  }, []);

  const onButton2Click = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='contactSectionContainer']"
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);


  return (
    <Layout>
      <div className={styles.aboutUs}>
        <div className={styles.aboutUsIntro}>
          <div className={styles.aboutUs1}>
            <div className={styles.aboutUsText}>
              <div className={styles.title}>
                <div className={styles.display}>About Us</div>
              </div>
              <div className={styles.heroText}>
                <div className={styles.p}>
                  <div className={styles.caption}>
                    We offer wholesale and retail customers the best medical and
                    clinical equipment. Whether for your private or clinical
                    practice, hospital upgrade or starting a new clinic – we
                    have a solution for all.
                  </div>
                </div>
                <div className={styles.p}>
                  <div className={styles.caption}>
                    We stand out because we focus on quality, packaging and
                    shipping policies. We also offer customized consultancies
                    for selecting the products that best fit your unique need.
                  </div>
                </div>
              </div>
            </div>
            <img className={styles.imageIcon} alt="" src="/image6@2x.png" />
          </div>
        </div>
        <div className={styles.keyValues}>
          <div className={styles.aboutUsSection}>
            <img
              className={styles.patternsIcon}
              alt=""
              src="/patterns3.svg"
            />
            <div className={styles.aboutUsFeatures}>
              <div className={styles.title}>
                <b className={styles.label}>What We Offer</b>
              </div>
              <div className={styles.grid2x2}>
                <div className={styles.row1}>
                  <div className={styles.feature}>
                    <img
                      className={styles.iconFilled2}
                      alt=""
                      src="/icon--filled10.svg"
                    />
                    <div className={styles.text}>
                      <div className={styles.title1}>
                        Wholesale of medical supply
                      </div>
                    </div>
                  </div>
                  <div className={styles.feature}>
                    <img
                      className={styles.iconFilled2}
                      alt=""
                      src="/icon--filled11.svg"
                    />
                    <div className={styles.text}>
                      <div className={styles.title1}>Health and wellness products for retail  </div>
                    </div>
                  </div>
                  <div className={styles.feature}>
                    <img
                      className={styles.iconFilled2}
                      alt=""
                      src="/icon--filled12.svg"
                    />
                    <div className={styles.text}>
                      <div className={styles.title1}>
                        Free consultation
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.aboutUsFeatures1}>
              <div className={styles.cardLabel1}>
                <b className={styles.label}>Why We Stand Out</b>
              </div>
              <div className={styles.grid2x21}>
                <div className={styles.row11}>
                  <div className={styles.feature}>
                    <img
                      className={styles.iconFilled2}
                      alt=""
                      src="/icon--filled10.svg"
                    />
                    <div className={styles.text}>
                      <div className={styles.title1}>
                        Fast and affordable global delivery
                      </div>
                    </div>
                  </div>
                  <div className={styles.feature}>
                    <img
                      className={styles.iconFilled2}
                      alt=""
                      src="/icon--filled11.svg"
                    />
                    <div className={styles.text}>
                      <div className={styles.title1}>
                        Wide range of branded products
                        
                      </div>
                    </div>
                  </div>
                  <div className={styles.feature}>
                    <img
                      className={styles.iconFilled2}
                      alt=""
                      src="/icon--filled12.svg"
                    />
                    <div className={styles.text}>
                      <div className={styles.title1}>
                        Efficient customer service 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.grid2x22}>
                <div className={styles.row12}>
                  <div className={styles.feature}>
                    <img
                      className={styles.iconFilled2}
                      alt=""
                      src="/icon--filled10.svg"
                    />
                    <div className={styles.text}>
                      <div className={styles.title1}>
                        Focus on both commercial and private practice
                      </div>
                    </div>
                  </div>
                  <div className={styles.feature}>
                    <img
                      className={styles.iconFilled2}
                      alt=""
                      src="/icon--filled10.svg"
                    />
                    <div className={styles.text}>
                      <div className={styles.title1}>
                        Great prices for retail and wholesale business
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutUs;
